import { FC, useCallback, useState } from "react";
import { getTrackBackground, Range } from "react-range";

import { useGameContext } from "./game-context";
import { Button } from "./DesignSystem";
import "./ConfigGame.scss";
import { STICKS, StickType } from "./engine/sticks";
import { Stick } from "./engine/game";
import classNames from "classnames";

export const ConfigGame: FC = () => {
  const { start } = useGameContext();
  const [players, setPlayers] = useState<string[]>(["", ""]);
  const [advanced, setAdvanced] = useState(false);
  const [mikadoMaxAppearance, setMikadoMaxAppearance] = useState(3);
  const [sticksCountReveal, setSticksCountReveal] = useState(3);
  const [set, setSets] = useState(
    (Object.keys(STICKS) as StickType[]).reduce((acc, type) => {
      acc[type] = STICKS[type].sticksCount;
      return acc;
    }, {} as Record<StickType, number>)
  );

  const onStart = useCallback(() => {
    let i = 0;
    const setConfig = (Object.keys(set) as StickType[]).reduce((acc, key) => {
      const stick = STICKS[key];
      [...Array(set[key])].forEach(() => {
        acc.push({
          id: i++,
          type: key,
          points: stick.points,
        });
      });

      return acc;
    }, [] as Stick[]);

    start({
      players: players.map((p, index) => ({
        name: p,
        id: index,
        playOrder: index,
      })),
      sticks: setConfig,
      mikadoMaxAppearance,
      sticksCountReveal,
    });
  }, [start, players, set, mikadoMaxAppearance, sticksCountReveal]);

  const onChange =
    (index: number) => (evt: React.ChangeEvent<HTMLInputElement>) => {
      let newPlayers = [...players];
      newPlayers[index] = evt.target.value;

      setPlayers(newPlayers);
    };

  const addPlayer = () => {
    setPlayers([...players, ""]);
  };

  const updateSet = (type: StickType) => (values: number[]) => {
    setSets({
      ...set,
      [type]: values[0],
    });
  };

  const removePlayer = (index: number) => () => {
    const newPlayers = players.filter((_, i) => i !== index);
    setPlayers(newPlayers);
  };

  const startable = players.every((p) => Boolean(p)) && players.length > 1;
  const removablePlayer = players.length > 2;

  return (
    <div className="config">
      <header className="header">Drinkado</header>
      <form className="config-form">
        {players.map((player, index) => (
          <div className="player" key={index}>
            <input
              className="player-input"
              placeholder="nom"
              key={index}
              onChange={onChange(index)}
              value={player}
            />
            <button
              className={classNames(
                "player-remove",
                !removablePlayer && "player-remove-disabled"
              )}
              type="button"
              disabled={players.length < 3}
              onClick={removePlayer(index)}
            >
              x
            </button>
          </div>
        ))}
      </form>
      <div className="config-actions">
        <Button onClick={addPlayer}>Ajouter un joueur</Button>
        <Button onClick={onStart} disabled={!startable}>
          Commencer la partie
        </Button>
      </div>
      <div>
        <p>
          configuration avancée{" "}
          <button type="button" onClick={() => setAdvanced(!advanced)}>
            {advanced ? "cacher" : "afficher"}
          </button>{" "}
        </p>
        {advanced && (
          <div className="custom-config">
            {(Object.keys(STICKS) as StickType[]).map((type) => (
              <div key={type} className="stick-config">
                <p>{STICKS[type].points}</p>
                <StickRange
                  key={type}
                  update={updateSet(type)}
                  values={[set[type]]}
                  min={type === "MIKADO" ? 1 : 0}
                />
              </div>
            ))}
            <div className="stick-config">
              <p>Nombre max d'apparition de Mikado</p>
              <StickRange
                update={(values) => setMikadoMaxAppearance(values[0])}
                values={[mikadoMaxAppearance]}
              />
            </div>
            <div className="stick-config">
              <p>Nombre d'indices</p>
              <StickRange
                update={(values) => setSticksCountReveal(values[0])}
                values={[sticksCountReveal]}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const StickRange: FC<{
  min?: number;
  values: number[];
  update: (values: number[]) => void;
}> = ({ values, update, min = 0 }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <Range
        step={1}
        min={min}
        max={100}
        values={values}
        onChange={update}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              {...props}
              style={{
                position: "relative",
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: values,
                  colors: ["#548BF4", "#ccc"],
                  min: 0,
                  max: 100,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "42px",
              width: "42px",
              borderRadius: "4px",
              backgroundColor: "#FFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-28px",
                color: "#fff",
                fontWeight: "bold",
                fontSize: "14px",
                fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
                padding: "4px",
                borderRadius: "4px",
                backgroundColor: "#548BF4",
              }}
            >
              {values[0]}
            </div>
            <div
              style={{
                height: "16px",
                width: "5px",
                backgroundColor: isDragged ? "#548BF4" : "#CCC",
              }}
            />
          </div>
        )}
      />
    </div>
  );
};
