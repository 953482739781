import { FC, useEffect, useRef } from "react";
import classNames from "classnames";
import { GameEvent, Stick } from "./engine/game";
import { useGameContext, EnhancedRound } from "./game-context";
import { STICKS, StickType } from "./engine/sticks";
import { ConfigGame } from "./ConfigGame";
import { Button } from "./DesignSystem";
import "./App.scss";
import Fireworks from "@fireworks-js/react";
import { Blood } from "./Blood";

export const App: FC = () => {
  const { currentRound, status, event } = useGameContext();

  return (
    <div className="container">
      <div className="app">
        {status === "INIT" && <ConfigGame />}
        {status === "STARTED" && currentRound && <Game round={currentRound} />}
      </div>

      {event &&
        ["GAME_LOST_APPEARANCE", "GAME_LOST_NOT_FOUND"].includes(
          event.name
        ) && <Blood />}
    </div>
  );
};

const Game: FC<{
  round: EnhancedRound;
}> = ({ round }) => {
  const { event, sticksCountReveal, getSticksCount } = useGameContext();

  return (
    <div>
      <CurrentRound round={round} />
      {event && <Event event={event} />}

      {sticksCountReveal > 0 && (
        <Button
          disabled={!!event}
          type="button"
          className="tips"
          onClick={getSticksCount}
        >
          ?
        </Button>
      )}
    </div>
  );
};

const CurrentRound: FC<{
  round: EnhancedRound;
}> = ({ round }) => {
  const { play } = useGameContext();

  return (
    <section className="round">
      <header className="round-player">{round.player.name}</header>
      <main>
        <>
          {round.tries.map((t, index) => (
            <Picker
              key={index}
              choices={t.choices}
              chosen={t.chosen}
              picked={t.picked}
              play={play}
            />
          ))}
        </>
      </main>
    </section>
  );
};

const Picker: FC<
  EnhancedRound["tries"][0] & { play: (choice: Stick["type"]) => void }
> = ({ choices, chosen, picked, play }) => {
  const alreadyPlayed = Boolean(picked);
  const success = picked === chosen;

  const onClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    play(evt.currentTarget.value as Stick["type"]);
  };

  const StickTypes = Object.keys(STICKS) as StickType[];

  return (
    <div className="sticks">
      {!alreadyPlayed && (
        <>
          {StickTypes.map((c) =>
            choices.includes(c) ? (
              <button
                className="stick sticks-clickable"
                key={c}
                type="button"
                value={c}
                onClick={onClick}
              >
                {STICKS[c].points}
              </button>
            ) : (
              <span key={c} className="stick stick-disabled">
                {STICKS[c].points}
              </span>
            )
          )}
        </>
      )}
      {alreadyPlayed && (
        <>
          {StickTypes.map((c) => (
            <span
              key={c}
              className={classNames("stick", {
                "stick-chosen": !success && chosen === c,
                "stick-picked": !success && picked === c,
                "stick-success": success && chosen === c,
                "stick-disabled": !choices.includes(c) && picked !== c,
                "stick-disabled-picked": !choices.includes(c) && picked === c,
              })}
            >
              {STICKS[c].points}
            </span>
          ))}
        </>
      )}
    </div>
  );
};

const EventImage: FC<React.ImgHTMLAttributes<HTMLImageElement>> = (props) => (
  <img {...props} className="event-image" />
);
const EventCard: FC<React.HTMLAttributes<HTMLDivElement>> = (props) => (
  <div {...props} className="event-card" />
);
const ReplayActions: FC = (props) => {
  const { restart, reset } = useGameContext();

  return (
    <div className="replay-actions">
      <Button onClick={restart} type="button" className="replay-button">
        Rejouer avec les mêmes joueurs
      </Button>
      <Button onClick={reset} type="button" className="replay-button">
        Commencer une nouvelle partie
      </Button>
    </div>
  );
};

const Event: FC<{
  event: GameEvent;
}> = ({ event }) => {
  const { next, hideSticksCount } = useGameContext();

  const byPassTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    next();
  };

  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const countRef = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    if (
      ["STICK_FOUND", "STICK_NOT_FOUND", "MIKADO_APPEARANCE"].includes(
        event.name
      )
    ) {
      timerRef.current = setTimeout(() => {
        next();
      }, 1000);

      return () => {
        clearTimeout(timerRef.current);
      };
    }
  }, [event, next]);

  useEffect(() => {
    if (event.name === "STICKS_COUNT") {
      countRef.current = setTimeout(() => {
        hideSticksCount();
      }, 1000);

      return () => {
        clearTimeout(countRef.current);
      };
    }
  }, [event, hideSticksCount]);

  return (
    <div className="event">
      {event.name === "GAME_WON" && (
        <>
          <EventCard>
            <EventImage
              src={process.env.PUBLIC_URL + "/img/won.webp"}
              alt="won"
            />
            <figcaption>Bien ouej, vous avez gagné</figcaption>
            <ReplayActions />
          </EventCard>
          <Fireworks
            options={{
              rocketsPoint: {
                min: 0,
                max: 100,
              },
            }}
            style={{
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              position: "fixed",
            }}
          />
        </>
      )}
      {event.name === "MIKADO_APPEARANCE" && (
        <EventCard>
          <EventImage
            onClick={byPassTimer}
            src={process.env.PUBLIC_URL + "/img/scared.webp"}
            alt="scared"
          />
          <figcaption>T'as loupé le mikado</figcaption>
        </EventCard>
      )}
      {event.name === "STICK_NOT_FOUND" && (
        <EventCard>
          <EventImage
            onClick={byPassTimer}
            src={process.env.PUBLIC_URL + "/img/not-found.webp"}
            alt="not found"
          />
          <figcaption>Tu t'es loupé</figcaption>
        </EventCard>
      )}
      {event.name === "STICK_FOUND" && (
        <EventCard>
          <EventImage
            onClick={byPassTimer}
            src={process.env.PUBLIC_URL + "/img/found.gif"}
            alt="found"
          />
          <figcaption>Bien ouej, essaye encore</figcaption>
        </EventCard>
      )}
      {["GAME_LOST_APPEARANCE", "GAME_LOST_NOT_FOUND"].includes(event.name) && (
        <>
          <EventCard>
            <EventImage
              src={process.env.PUBLIC_URL + "/img/lost.webp"}
              alt="lost"
            />
            {event.name === "GAME_LOST_APPEARANCE" && (
              <figcaption>Le Mikado est apparu {event.total} fois</figcaption>
            )}
            {event.name === "GAME_LOST_NOT_FOUND" && (
              <figcaption>Il reste un seul mikado loser</figcaption>
            )}
            <ReplayActions />
          </EventCard>
        </>
      )}
      {event.name === "STICKS_COUNT" && (
        <EventCard>
          <p className="count-event">Il reste {event.count} mikados</p>
        </EventCard>
      )}
    </div>
  );
};
