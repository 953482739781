import { FC } from "react";

import "./Blood.scss";

export const Blood: FC = () => (
  <div className="blood">
    {[...Array(10)].map(() => (
      <div className="blood-item" />
    ))}
  </div>
);
