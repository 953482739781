import { FC } from "react";
import classNames from "classnames";

import "./DesignSystem.scss";

export const Button: FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  disabled,
  className,
  ...rest
}) => (
  <button
    {...rest}
    className={classNames("bn39", disabled && "bn39-disabled", className)}
    disabled={disabled}
  >
    <span className="bn39span">{children}</span>
  </button>
);
