import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import { Config, Game, GameEvent, Player, Round, Stick } from "./engine/game";

type GameStatus = "INIT" | "STARTED" | "FINISHED";

export type EnhancedRound = Round & { player: Player };

export type ContextConfig = Config & { sticksCountReveal?: number };

export type GameContextType = {
  status: GameStatus;
  players: Player[];
  currentRound?: EnhancedRound;
  event?: GameEvent;
  sticksCountReveal: number;
  play: (
    choice: Stick["type"]
  ) => { found: boolean; picked: Stick } | undefined;
  start: (config: ContextConfig) => void;
  next: () => void;
  restart: () => void;
  reset: () => void;
  getSticksCount: () => void;
  hideSticksCount: () => void;
};

const GameContext = React.createContext<GameContextType>({} as GameContextType);

function getCurrentRound(game: Game) {
  const round = game.getCurrentRound();
  const player = game.getPlayers().find((p) => p.id === round.playerId);

  if (player) {
    return { ...round, player };
  }
}

export const GameContextProvider: FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [status, setStatus] = useState<GameStatus>("INIT");
  const [players, setPlayers] = useState<Player[]>([]);
  const [sticksCountReveal, setStickCountReveal] = useState(0);
  const [currentRound, setCurrentRound] = useState<EnhancedRound>();
  const [event, setEvent] = useState<GameEvent>();
  const gameRef = React.useRef<Game>();
  const configRef = React.useRef<Config>();

  const onEvent = useCallback(
    (evt: GameEvent) => {
      setEvent(evt);
      return undefined;
    },
    [setEvent]
  );

  const next = useCallback(() => {
    setEvent(undefined);

    if (gameRef.current) {
      const next = gameRef.current.next();
      setCurrentRound(getCurrentRound(gameRef.current));
      return next;
    }
  }, []);

  const start = useCallback(
    (config: ContextConfig) => {
      configRef.current = config;
      gameRef.current = new Game(config);
      gameRef.current.registerEmitListener(onEvent);
      gameRef.current.shuffleSticks();

      setStatus("STARTED");
      setPlayers(config.players);
      if (config.sticksCountReveal) {
        setStickCountReveal(config.sticksCountReveal);
      }

      next();
    },
    [onEvent, next, setEvent]
  );

  const restart = useCallback(() => {
    if (configRef.current) {
      start(configRef.current);
    }
  }, [start]);

  const reset = useCallback(() => {
    if (configRef.current) {
      configRef.current = undefined;
    }
    setEvent(undefined);
    setStatus("INIT");
  }, [setStatus]);

  const play = useCallback((choice: Stick["type"]) => {
    if (gameRef.current) {
      const play = gameRef.current.play(choice);
      setCurrentRound(getCurrentRound(gameRef.current));
      return play;
    }
  }, []);

  const getSticksCount = useCallback(() => {
    if (gameRef.current) {
      gameRef.current.getSticksCount();
    }
  }, []);

  const hideSticksCount = useCallback(() => {
    setEvent(undefined);
    setStickCountReveal(sticksCountReveal - 1);
  }, [sticksCountReveal, setStickCountReveal]);

  const contextValue = useMemo(
    () => ({
      players,
      currentRound,
      status,
      event,
      sticksCountReveal,
      start,
      play,
      next,
      restart,
      reset,
      getSticksCount,
      hideSticksCount,
    }),
    [
      players,
      currentRound,
      status,
      event,
      sticksCountReveal,
      start,
      play,
      next,
      restart,
      reset,
      getSticksCount,
      hideSticksCount,
    ]
  );

  return (
    <GameContext.Provider value={contextValue}>{children}</GameContext.Provider>
  );
};

export function useGameContext() {
  const context = useContext(GameContext);
  if (context === undefined) {
    throw new Error("GameContext must be within GameContextProvider");
  }

  return context;
}
