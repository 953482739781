export const STICKS = {
  KULI: {
    points: 2,
    sticksCount: 15,
  },
  SAMURAI: {
    points: 3,
    sticksCount: 15,
  },
  BONZEN: {
    points: 5,
    sticksCount: 5,
  },
  MANDARIN: {
    points: 10,
    sticksCount: 5,
  },
  MIKADO: {
    points: 20,
    sticksCount: 1,
  },
} as const;

export type StickType = keyof typeof STICKS;

type Stick = {
  id: number;
  type: StickType;
  points: number;
};

let i = 0;
export const STICKS_SET = (Object.keys(STICKS) as StickType[]).reduce(
  (acc, key) => {
    const stick = STICKS[key];
    [...Array(stick.sticksCount)].forEach(() => {
      acc.push({
        id: i++,
        type: key,
        points: stick.points,
      });
    });

    return acc;
  },
  [] as Stick[]
);
