import shuffle from "lodash/shuffle";
import difference from "lodash/difference";

import { STICKS, STICKS_SET, StickType } from "./sticks";

const StickTypes = Object.keys(STICKS) as StickType[];

export type Stick = {
  id: number;
  type: StickType;
  points: number;
  picked?: boolean;
};

export type Player = {
  id: number;
  name: string;
  playOrder: number;
};

export type GameEvent =
  | { roundId: Round["id"]; name: "STICK_FOUND" }
  | { roundId: Round["id"]; name: "STICK_NOT_FOUND" }
  | { roundId: Round["id"]; name: "GAME_LOST_APPEARANCE"; total: number }
  | { roundId: Round["id"]; name: "GAME_LOST_NOT_FOUND" }
  | { roundId: Round["id"]; name: "MIKADO_APPEARANCE" }
  | { roundId: Round["id"]; name: "GAME_WON" }
  | { count: number; name: "STICKS_COUNT" };

export type Round = {
  id: number;
  playerId: Player["id"];
  ended?: boolean;
  tries: {
    choices: Stick["type"][];
    chosen?: Stick["type"];
    picked?: Stick["type"];
  }[];
};

export type Config = {
  players: Player[];
  rounds?: Round[];
  sticks?: Stick[];
  getRandomIndex?: (size: number) => number;
  mikadoMaxAppearance?: number;
};

type Status = "RUNNING" | "LOST_APPEARANCE" | "LOST_NOT_FOUND" | "WON";

export class Game {
  private sticks: Stick[];
  private players: Player[];
  private rounds: Round[];
  private selectedSticks: number[] = [];
  private getRandomIndex: (size: number) => number;
  private mikadoMaxAppearance;
  private mikadoAppearanceCount = 0;
  private numberOfMikado = 0;
  private status: Status = "RUNNING";
  private onEvent = (evt: GameEvent) => undefined;

  constructor({
    players,
    rounds = [],
    sticks = STICKS_SET,
    mikadoMaxAppearance = 3,
    getRandomIndex = (size: number) => Math.floor(Math.random() * size),
  }: Config) {
    this.sticks = sticks;
    this.players = players;
    this.rounds = rounds;
    this.getRandomIndex = getRandomIndex;
    this.mikadoMaxAppearance = mikadoMaxAppearance;
    this.numberOfMikado = sticks.filter((s) => s.type === "MIKADO").length;
  }

  shuffleSticks() {
    this.sticks = shuffle(this.sticks);
  }

  getSticks() {
    return this.sticks;
  }

  getSticksCount() {
    const sticks = this.getAvailableSticks();
    this.emitEvent({ count: sticks.length, name: "STICKS_COUNT" });
    return sticks;
  }

  private startNewRound() {
    if (this.rounds.length === 0) {
      this.rounds.push({
        id: 0,
        playerId: this.players.find((p) => p.playOrder === 0)?.id as number,
        tries: [
          {
            choices: StickTypes,
          },
        ],
      });
      return;
    }

    const currentRound = this.getCurrentRound();
    const currentPlayer = this.players.find(
      (p) => p.id === currentRound.playerId
    ) as Player;
    const nextOrder = (currentPlayer.playOrder + 1) % this.players.length;
    const nextPlayer = this.players.find(
      (p) => p.playOrder === nextOrder
    ) as Player;

    const previousChoice = this.getPreviousChoice(nextPlayer.id);

    this.rounds.push({
      id: currentRound.id + 1,
      playerId: nextPlayer.id,
      tries: [
        {
          choices: !previousChoice
            ? StickTypes
            : (difference(StickTypes, [previousChoice]) as Stick["type"][]),
        },
      ],
    });
  }

  getCurrentRound() {
    return this.rounds[this.rounds.length - 1];
  }

  play(choice: Stick["type"]) {
    const status = this.getStatus();

    if (status !== "RUNNING") throw new Error("ended");

    const round = this.getCurrentRound();
    const currentTry = round.tries[round.tries.length - 1];
    if (!currentTry.choices.includes(choice)) {
      throw new Error("unauthorized choice", {
        cause: {
          choice,
          valid: currentTry.choices,
        },
      });
    }

    const picked = this.pick();
    currentTry.chosen = choice;
    currentTry.picked = picked.type;

    const found = choice === picked.type;

    let newStatus: Status = status;

    if (found) {
      if (choice !== "MIKADO" && round.tries.length === 4) {
        round.ended = true;
        newStatus = "WON";
        this.emitEvent({ roundId: round.id, name: "GAME_WON" });
      } else if (choice !== "MIKADO") {
        const alreadyChosen = round.tries.map((r) => r.chosen);
        round.tries.push({
          choices: difference(StickTypes, alreadyChosen) as Stick["type"][],
        });
        this.emitEvent({ roundId: round.id, name: "STICK_FOUND" });
      } else if (choice === "MIKADO") {
        round.ended = true;
        newStatus = "WON";
        this.emitEvent({ roundId: round.id, name: "GAME_WON" });
      }
    } else if (!found) {
      const availableSticks = this.getAvailableSticks();
      if (picked.type === "MIKADO") {
        round.ended = true;
        this.mikadoAppearanceCount += 1;
        if (this.mikadoAppearanceCount === this.mikadoMaxAppearance) {
          newStatus = "LOST_APPEARANCE";
          this.emitEvent({
            roundId: round.id,
            name: "GAME_LOST_APPEARANCE",
            total: this.mikadoMaxAppearance,
          });
        } else {
          this.putBackMidado();
          this.emitEvent({ roundId: round.id, name: "MIKADO_APPEARANCE" });
        }
      } else if (availableSticks.length === this.numberOfMikado) {
        round.ended = true;
        newStatus = "LOST_NOT_FOUND";
        this.emitEvent({ roundId: round.id, name: "GAME_LOST_NOT_FOUND" });
      } else {
        this.emitEvent({ roundId: round.id, name: "STICK_NOT_FOUND" });
        round.ended = true;
      }
    }
    this.status = newStatus;

    return { found, picked, status: newStatus };
  }

  getAvailableSticks() {
    return this.sticks.filter((s) => !this.selectedSticks.includes(s.id));
  }

  pick() {
    const availableSticks = this.getAvailableSticks();
    const index = this.getRandomIndex(availableSticks.length);
    const picked = availableSticks[index];
    this.selectedSticks.push(picked.id);
    return picked;
  }

  putBackMidado() {
    const mikado = this.sticks.find((s) => s.type === "MIKADO");
    if (mikado) {
      this.selectedSticks = this.selectedSticks.filter(
        (id) => id !== mikado.id
      );
    }
  }

  next() {
    const finish = this.getStatus() !== "RUNNING";
    const currentRound = this.getCurrentRound();

    if (!finish && (!currentRound || currentRound.ended)) {
      this.startNewRound();
    }

    return { finish };
  }

  getStatus() {
    return this.status;
  }

  getPlayers() {
    return this.players;
  }

  getPreviousChoice(playerId: number) {
    const previousRounds = this.rounds.filter((r) => r.playerId === playerId);

    if (previousRounds.length === 0) return undefined;

    const previousTries = previousRounds[previousRounds.length - 1].tries;

    const previousChoice = previousTries[previousTries.length - 1].chosen;

    return previousChoice;
  }

  registerEmitListener(cb: (evt: GameEvent) => undefined) {
    this.onEvent = cb;
  }

  emitEvent(evt: GameEvent) {
    this.onEvent(evt);
  }
}
